import { useContext, useState } from "react";
import "./BuyNowBtn.scss";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { adminPath } from "../../constants/api";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import {
  getUserData,
  jsonDecryptAES,
  jsonEncryptAES,
} from "../../utils/common";
import { jwtDecode } from "jwt-decode";
import { paymentStatus } from "../../constants/constants";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import { useNavigate } from "react-router-dom";
import CartBtn from "../CartBtn/CartBtn";
import { CartModalContext } from "../../contexts/CartModalContext";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";
const BuyNowBtn = (props: any) => {
  //const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { setCart } = useContext(CartModalContext);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { setToster } = useContext(SnackbarContext);
  const userData = getUserData();

  // const handleFailure = async () => {
  //   try {
  //     const d = new Date();
  //     let year = d.getFullYear();
  //     let day = d.getDay();
  //     let month = d.getMonth() + 1;
  //     const id =
  //       year.toString() +
  //       day.toString() +
  //       month.toString() +
  //       Math.floor(100000 + Math.random() * 900000);
  //     const token: any = sessionStorage.getItem("token");
  //     const userData: any = jwtDecode(token);
  //     let result = new Date();
  //     result.setDate(d.getDate() + props.paydata.duration);
  //     props.paydata["endDate"] = result;
  //     let obj = {
  //       orderID: id,
  //       userType: userData.user.role,
  //       IHSID: userData.user.IHSID,
  //       status: paymentStatus.Failure,
  //       purchasedDate: dayjs(),
  //       purchasedItems: [props.paydata],
  //       endDate: result,
  //       date: new Date(),
  //     };
  //     const encryptAESData = await jsonEncryptAES(obj);
  //     const response: any = await authInterceptor.post(`${adminPath}order`, {
  //       data: encryptAESData,
  //     });
  //     navigate("/my-package");
  //     //window.location.reload();
  //     if (response) {
  //       setToster({
  //         message: response?.data?.msg,
  //         type: "success",
  //       });
  //     }
  //   } catch (error: any) {
  //     if (error.response) {
  //       setToster({
  //         message: error?.response?.data?.msg,
  //         type: "error",
  //       });
  //     }
  //   }
  // };
  const getSpecExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}specialityTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModuleExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}moduleTest/FE/getAll/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const getExamData = async (code: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}onlineTest/FE/subject/${code}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getPackageExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}onlineTest/FE/package`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getAllModuleExamData = async (list: any) => {
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}moduleTest/FE/getAll`,
        { data: list }
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      return decryptAESData;
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const handleSuccess = async () => {
    try {
      const d = new Date();
      let month: any = d.getMonth();
      let day = d.getDate().toString().padStart(2, "0");
      let year: any = d.getFullYear();
      year = year.toString().substr(-2);
      month = (month + 1).toString().padStart(2, "0");
      const id =
        year + day + month + Math.floor(100000 + Math.random() * 900000);
      const token: any = sessionStorage.getItem("token");
      const userData: any = jwtDecode(token);
      let result = new Date();
      result.setDate(d.getDate() + Number(props.paydata.duration));
      props.paydata["endDate"] = result;
      if (
        props.purchaseType === "Package" ||
        props.purchaseType === "SpecPackage" ||
        props.purchaseType === "Speciality"
      ) {
        props.paydata["isPackage"] = true;
      }
      let examList;
      if (props.purchaseType === "Package") {
        examList = await getPackageExamData(props.paydata?.list);
      } else if (props.purchaseType === "Subject") {
        examList = await getExamData(props.paydata.code);
      } else if (props.purchaseType === "Module") {
        examList = await getModuleExamData(props.paydata.code);
      } else if (props.purchaseType === "SpecPackage") {
        examList = await getSpecExamData(props.paydata.list);
      } else if (props.purchaseType === "Speciality") {
        const moduleData = await getAllModuleExamData(props.paydata.list);
        const specData = await getSpecExamData([props.paydata.code]);
        examList = [...specData, ...moduleData];
      }

      let PQDObj: any = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        modeOfActivation: "Purchased",
        packagestatus: "Active",
        purchasedDate: dayjs(),
        packageData: { ...props.paydata },
        examData: examList,
      };
      const encryptPQD = await jsonEncryptAES(PQDObj);
      //Local Development
      // const purchasedDataID = await authInterceptor.post(
      //   `${adminPath}PQD/FE/`,
      //   {
      //     data: encryptPQD,
      //   }
      // );
      // props.paydata["purchasedDataID"] = purchasedDataID?.data?.id;
      // ---------- //

      let obj = {
        orderID: id,
        userType: userData.user.role,
        IHSID: userData.user.IHSID,
        userName: userData.user.name,
        status: paymentStatus.Success,
        modeOfActivation: "Purchased",
        packagestatus: "Active",
        purchasedItems: [props.paydata],
        purchasedDate: dayjs(),
        endDate: result,
        date: new Date(),
      };
      //Local Development
      // const encryptAESData = await jsonEncryptAES(obj);
      // const response: any = await authInterceptor.post(`${adminPath}order`, {
      //   data: encryptAESData,
      // });

      // await authInterceptor.post(`${adminPath}common//SMS/purchasesms`, {
      //   packagename: props.paydata?.title,
      //   IHSID: userData.user.IHSID,
      // });
      // navigate("/my-package");
      // ---------- //

      // Prod
      const purchaseTempData: any = await authInterceptor.post(
        `${adminPath}purchaseTemp/`,
        {
          PQDObj: encryptPQD,
          orderObj: obj,
          paydata: props.paydata,
        }
      );

      const apiResponse = await authInterceptor.post(
        `${adminPath}payment/paymentrequest`,
        {
          amount: props.paydata,
          purchasedDataID: "",
          orderID: id || "000",
          packagename: purchaseTempData?.data?.id || "",
          IHSID: userData.user.IHSID,
        }
      );
      window.location.href = apiResponse?.data?.url;
      // ---------- //
    } catch (error: any) {
      if (error.response) {
        setToster({
          message: error?.response?.data?.msg,
          type: "error",
        });
      }
    }
  };
  const getCartItems = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}cartItems/${userData.user.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      const cartData = decryptAESData.map((data: any) => data?.cartList?.code);
      const purchaseType = decryptAESData.map(
        (data: any) => data?.purchaseType
      );
      return { cartData, purchaseType };
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const updateCart = async () => {
    const { cartData, purchaseType }: any = await getCartItems();
    if (
      purchaseType.includes(
        props.purchaseType === "Speciality" ? "Module" : "Speciality"
      )
    ) {
      toast.error(
        `Already ${
          props.purchaseType === "Speciality" ? "Module" : "Speciality"
        } in cart`,
        {
          position: "top-center",
        }
      );
      return;
    }
    if (cartData.includes(props.paydata.code)) {
      window.scrollTo(0, 0);
      setCart({ triggerPopup: true, type: props.purchaseType });
      return;
    }
    try {
      const response: any = await authInterceptor.post(
        `${adminPath}cartItems`,
        {
          IHSID: userData.user.IHSID,
          userName: userData.user.name,
          cartList: props.paydata,
          type: userData.user.role,
          purchaseType: props.purchaseType,
        }
      );
      if (response) {
        window.scrollTo(0, 0);
        setCart({ triggerPopup: true, type: props.purchaseType });
      }
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  return (
    <>
      <div className="buynow-button-wrapper">
        <Button
          variant="primary"
          onClick={handleSuccess}
          className={`buynow-button ${props.purchaseType === "Subject" ? "subjectBtn" : ""}`}
          size="lg"
        >
          Buy Now
        </Button>
        <CartBtn isSubjectBtn={props.purchaseType === "Subject"} updateCart={updateCart} />
      </div>
    </>
  );
};

export default BuyNowBtn;
